


.privacy-policy-main{
    margin: 128px 0;
    .container{
        .wrapper{
            .desc{
                color: $Shade-1;
            }
            .para{
                padding: 0 0 16px 0;
            }
            .heading{
                margin: 24px 0 16px 0;
                color: $White-color;
            }
        }
    }
}