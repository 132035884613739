

/*------------------------------------------
        Hero home2 Start Here
-------------------------------------------*/

.hero2-main{
    background-color: #001132;
    .container-fluid{
        position: relative;
        .navbar-main-2{
            .wrapper{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 32px 0;
                position: relative;
                z-index: 10;
                @include breakpoint($secreen-max-lg){
                    padding: 24px 0;
                }
                .logo-otr{
                    .logo-a{
                        .logo-img{
                            width: 134px;
                            @include breakpoint($secreen-xs){
                                width: 110px;
                            }
                        }
                    }
                }
                .navigation-otr{
                    margin: 0 auto;
                    @include breakpoint($secreen-max-lg){
                        display: none;
                    }
                    .navigation-inr{
                        display: flex;
                        align-items: center;
                        .navigation-li{
                            position: relative;
                            &:not(:last-child){
                                margin-right: 40px;
                            }
                            &:hover{
                                .drop-down-ul{
                                    visibility: visible;
                                    opacity: 1;
                                    top: 50px;
                                }
                            }
                            .nav-a{
                                color: $White-color;
                                line-height: 50px;
                                transition: .2s;
                                cursor: pointer;
                                &:hover{
                                    color: $Primary-color;
                                }
                            }
                            .drop-down-ul{
                                position: absolute;
                                left: -24px;
                                top: 70px;
                                width: 230px;
                                border-radius: 24px;
                                background-color: $Primary-shade;
                                padding: 16px 24px 20px 24px;
                                opacity: 0;
                                visibility: hidden;
                                transition: .3s;
                                z-index: 10;
                                .drop-down-li{
                                    position: relative;
                                    &:not(:last-child){
                                        margin-bottom: 12px;
                                    }
                                    &:hover{
                                        .drop-down-ul-other{
                                            opacity: 1;
                                            visibility: visible;
                                        }
                                    }
                                    .drop-down-a{
                                        color: $White-color;
                                        display: inline;
                                        font-family: $font-family-Poppins-Medium;
                                        transition: .2s;
                                        cursor: pointer;
                                        &:hover{
                                            color: $Primary-color;
                                        }
                                    }
                                    .drop-down-ul-other{
                                        position: absolute;
                                        left: 180px;
                                        top: -24px;
                                        width: 230px;
                                        border-radius: 24px;
                                        background-color: $Primary-shade;
                                        padding: 16px 24px 20px 24px;
                                        opacity: 0;
                                        visibility: hidden;
                                        transition: .3s;
                                        z-index: 10;
                                        .drop-down-li-other{
                                            position: relative;
                                            &:not(:last-child){
                                                margin-bottom: 12px;
                                            }
                                            .drop-down-a-other{
                                                color: $White-color;
                                                font-family: $font-family-Poppins-Medium;
                                                display: inline;
                                                transition: .2s;
                                                &:hover{
                                                    color: $Primary-color;
                                                }
                                            }
                                        }       
                                    }
                                }
                            }
                        }
                        .nav-li4{
                            .nav-a{
                                letter-spacing: 2px;
                                position: relative;
                                font-family: "Poppins-Bold";
                                top: -2px;
                            }
                        }
                    }
                }
                .action-nav{
                    display: flex;
                    align-items: center;
                    .search-main{
                        position: relative;
                        width: 280px;
                        @include breakpoint($secreen-max-xlg){
                            width: 200px;
                        }
                        @include breakpoint($secreen-max-lg){
                            display: none;
                        }
                        .input{
                            width: 280px;
                            @include breakpoint($secreen-max-xlg){
                                width: 200px;
                            }
                        }
                        .search-icon{
                            position: absolute;
                            top: 50%;
                            right: 24px;
                            color: $White-color;
                            transform: translateY(-50%);
                            font-size: 16px;
                        }
                    }
                    .action{
                        @include breakpoint($secreen-max-lg){
                            display: none;
                        }
                    }
                    .notification-main{
                        position: relative;
                        @include breakpoint($secreen-max-md){
                            display: block !important;
                        }
                        @include breakpoint($secreen-max-sm){
                            position: inherit;
                        }
                        .icon-notification-otr{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            position: relative;
                            width: 56px;
                            height: 56px;
                            border-radius: 100%;
                            background-color: $White-Bg-1;
                            @include breakpoint($secreen-xs){
                                width: 40px;
                                height: 40px;
                            }
                            .notification-icon{
                                color: $White-color;
                                font-size: 24px;
                                cursor: pointer;
                                @include breakpoint($secreen-xs){
                                    font-size: 18px;
                                }
                            }
                            .dot{
                                width: 8px;
                                height: 8px;
                                border-radius: 50px;
                                background-color: $Primary-color;
                                position: absolute;
                                top: 4px;
                                right: 4px;
                            }
                        }
                        .notification-drop{
                            position: absolute;
                            background-color: $Primary-shade;
                            width: 400px;
                            border-radius: 24px;
                            padding: 24px 28px 32px 28px;
                            top: 100px;
                            right: 0;
                            z-index: 10;
                            opacity: 0;
                            visibility: hidden;
                            transition: .3s;
                            @include breakpoint($secreen-max-sm){
                                width: 100%;
                                padding: 24px 15px 32px 15px;
                            }
                            .Heading-otr{
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                margin: 0 0 24px 0;
                                .heading{
                                    color: $White-color;
                                }
                                .linkk{
                                    color: $Primary-color;
                                }
                            }
                            .notification-ul{
                                .notification-li{
                                    &:not(:last-child){
                                        margin: 0 0 16px 0;
                                    }
                                    .notification-a{
                                        display: flex;
                                        align-items: center;
                                        .img-otr{
                                            width: 70px;
                                            height: 70px;
                                            margin: 0 16px 0 0;
                                            .img{
                                                height: 100%;
                                                border-radius: 6px;
                                            }
                                        }
                                        .content-otr{
                                            .name{
                                                color: $White-color;
                                                margin: 0 0 6px 0;
                                            }
                                            .price{
                                                color: $Shade-1;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .notification-drop-open{
                            top: 60px;
                            opacity: 1;
                            visibility: visible;
                            @include breakpoint($secreen-max-sm){
                                top: 80px;
                            }
                        }
                    }
                    .right-space{
                        @include breakpoint($secreen-max-md){
                            display: none;
                        }
                        &:not(:last-child){
                            margin-right: 24px;
                            @include breakpoint($secreen-xs){
                                margin-right: 0px;
                            }
                        }
                    }
                    .profile-nav-main{
                        position: relative;
                        @include breakpoint($secreen-max-lg){
                            margin-right: 24px;
                        }
                        @include breakpoint($secreen-max-sm){
                            position: inherit;
                        }
                        @include breakpoint($secreen-xs){
                            margin: 0 16px;
                        }
                        .profile-nav{
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                            background-color: $White-Bg-1;
                            border-radius: 12px;
                            height: 56px;
                            @include breakpoint($secreen-xs){
                                height: 40px;
                            }
                            .img-otr{
                                .nav-prof-img{
                                    height: 56px;
                                    width: 56px;
                                    border-radius: 12px 0 0 12px;
                                    @include breakpoint($secreen-xs){
                                        width: 40px;
                                        height: 40px;
                                        border-radius: 12px;
                                    }
                                }
                            }
                            .desc{
                                color: $White-color;
                                padding: 0 16px;
                                @include breakpoint($secreen-xs){
                                    display: none;
                                }
                            }
                        }
                        .profile-pop-otr{
                            position: absolute;
                            background-color: $Primary-shade;
                            width: 300px;
                            border-radius: 24px;
                            padding: 24px 28px 32px 28px;
                            top: 100px;
                            right: 0;
                            z-index: 10;
                            opacity: 0;
                            visibility: hidden;
                            transition: .3s;
                            @include breakpoint($secreen-max-sm){
                                width: 100%;
                                padding: 24px 15px;
                            }
                            .copy-icon-otr{
                                display: flex;
                                align-items: center;
                                .text{
                                    color: $White-color;
                                }
                                .copy-icon{
                                    font-size: 24px;
                                    color: $White-color;
                                }
                            }
                            .balance-otr{
                                display: flex;
                                align-items: center;
                                margin: 16px 0;
                                .img-etherem{
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    background-color: $White-color;
                                    width: 56px;
                                    height: 56px;
                                    border-radius: 100%;
                                    .etherem{
                                        height: 32px;
                                    }
                                }
                                .balance{
                                    margin-left: 16px;
                                    .text{
                                        color: $Shade-1;
                                    }
                                    .price{
                                        color: $White-color;
                                    }
                                }
                            }
                            .link-profile-ul{
                                .link-profile-li{
                                    &:not(:last-child){
                                        margin-bottom: 12px;
                                    }
                                    .link-profile-a{
                                        display: inline;
                                        color: $White-color;
                                        transition: .3s;
                                        &:hover{
                                            color: $Primary-color;
                                        }
                                    }
                                }
                            }
                        }
                        .profile-pop-open{
                            top: 60px;
                            opacity: 1;
                            visibility: visible;
                            @include breakpoint($secreen-max-sm){
                                top: 80px;
                            }
                        }
                    }
                    .burger-icon-otr{
                        display: none;
                        align-items: center;
                        justify-content: center;
                        width: 56px;
                        height: 56px;
                        border-radius: 100%;
                        background-color: $White-Bg-1;
                        cursor: pointer;
                        @include breakpoint($secreen-max-lg){
                            display: flex;
                        }
                        @include breakpoint($secreen-xs){
                            width: 40px;
                            height: 40px;
                        }
                        .burger-icon{
                            font-size: 24px;
                            color: $White-color;
                            @include breakpoint($secreen-xs){
                                font-size: 18px;
                            }
                        }
                    }
                }
            }
        }
        .row-custom{
            position: relative;
            z-index: 2;
            padding: 96px 0 128px 0;
            .col-content-otr{
                @include breakpoint($secreen-max-xxlg){
                    width: 50%;
                }
                @include breakpoint($secreen-max-lg){
                    width: 60%;
                }
                @include breakpoint($secreen-max-md){
                    width: 80%;
                }
                @include breakpoint($secreen-max-sm){
                    width: 100%;
                }
                .col-content-inr{
                    .heading{
                        color: $White-color;
                    }
                    .desc{
                        color: $White-color;
                        margin: 20px 0 40px 0;
                        @include breakpoint($secreen-xs){
                            font-size: $font-size-S;
                            line-height: $line-height-S;
                        }
                    }
                    .action{
                        display: flex;
                        align-items: center;
                        @include breakpoint($secreen-xxs){
                            flex-direction: column;
                            text-align: center;
                        }
                        .btn-hero{
                            @include breakpoint($secreen-xxs){
                                width: 100%;
                            }
                            &:not(:last-child){
                                margin: 0 24px 0 0;
                                @include breakpoint($secreen-xxs){
                                    margin: 0 0 24px 0;
                                }
                            }
                        }
                    }
                }
            }
        }
        .hero-img{
            position: absolute;
            height: 85%;
            right: 0;
            bottom: 0;
            @include breakpoint($secreen-max-xxlg){
                right: 0;
                height: 80%;
            }
            @include breakpoint($secreen-max-md){
                display: none;
            }
        }
    }
}

/*------------------------------------------
        Hero home2 End Here
-------------------------------------------*/

/*------------------------------------------
            Creator Start Here
-------------------------------------------*/

.creator-home2-main{
    margin: 128px 0;
    .container-fluid{
        .wrapper{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0 0 72px 0;
            .heading{
                color: $White-color;
            }
            .view-all{
                display: flex;
                align-items: center;
                @include breakpoint($secreen-xs){
                    display: none;
                }
                .view{
                    color: $Primary-color;
                    margin: 0 12px 0 0;
                }
                .arrow-right{
                    color: $Primary-color;
                    font-size: 24px;
                }
            }
        }
        .row-creator{
            .col-otr{
                @include breakpoint($secreen-lg){
                    width: 25%;
                }
                .col-inr{
                    .col-box{
                        display: flex;
                        align-items: flex-start;
                        flex-direction: column;
                        background-color: $White-Bg-1;
                        border-radius: 16px;
                        padding: 24px 28px;
                        .avtar-otr{
                            width: 62px;
                            height: 62px;
                            border-radius: 100px;
                            position: relative;
                            .avatar{
                                height: 100%;
                                border-radius: 100%;
                                border: 2px solid $White-color;
                            }
                            .check-icon-otr{
                                width: 20px;
                                height: 20px;
                                border-radius: 100px;
                                background-color: $Primary-color;
                                border: 2px solid $White-color;
                                position: absolute;
                                display: flex;
                                right: 2px;
                                bottom: 0;
                                @include breakpoint($secreen-xs){
                                    width: 16px;
                                    height: 16px;
                                }
                                .check-icon{
                                    position: relative;
                                    top: 40%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                    color: $White-color;
                                    font-size: 14px;
                                    @include breakpoint($secreen-xs){
                                        top: 30%;
                                        font-size: 12px;
                                    }
                                }
                            }
                        }
                        .content-otr{
                            margin: 16px 0 0 0;
                            .creator-name{
                                color: $White-color;
                            }
                            .price{
                                color: $Primary-color;
                            }
                        }
                    }
                }
                .box-1,.box-2,.box-3,.box-4,.box-5,.box-6{
                    margin: 0 0 24px 0;
                }
                .box-1,.box-2,.box-3,.box-4,.box-5,.box-6,.box-7,.box-8{
                    @include breakpoint($secreen-max-md){
                        margin: 0 0 24px 0;
                    }
                }
                .box-1,.box-2,.box-3,.box-4,.box-5,.box-6,.box-7,.box-8,.box-9,.box-10{
                    @include breakpoint($secreen-max-sm){
                        margin: 0 0 24px 0;
                    }
                }
                .box-1,.box-2,.box-3,.box-4,.box-5,.box-6,.box-7,.box-8,.box-9,.box-10,.box-11{
                    @include breakpoint($secreen-xs){
                        margin: 0 0 24px 0;
                    }
                }
            }
        }
        .responsive{
            display: none;
            align-items: center;
            justify-content: center;
            margin: 48px 0 0 0;
            @include breakpoint($secreen-xs){
                display: flex;
            }
            .view-all{
                display: flex;
                align-items: center;
                .view{
                    color: $Primary-color;
                    margin: 0 12px 0 0;
                }
                .arrow-right{
                    color: $Primary-color;
                    font-size: 24px;
                }
            }
        }
    }
}

/*------------------------------------------
            Creator End Here
-------------------------------------------*/