

/*------------------------------------------
          Connect Wallet Start Here
-------------------------------------------*/

.connect-wallet-main{
    margin: 128px 0;
    .container-fluid{
        .wrapper{
            margin: 0 0 72px 0;
            .heading{
                color: $White-color;
            }
        }
        .row-custom{
            display: flex;
            align-items: center;
            .col-box-otr{
                .col-box-inr{
                    @include breakpoint($secreen-max-md){
                        padding-bottom: 72px;
                    }
                    .row-custom-inr{
                        .col-wallet-otr{
                            @include breakpoint($secreen-lg){
                                width: 80%;
                            }
                            .col-wallet-inr{
                                background-color: $White-Bg-1;
                                border-radius: 24px;
                                padding: 28px 32px 24px 32px;
                                .icon-otr{
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    width: 140px;
                                    height: 140px;
                                    border-radius: 12px;
                                    margin: 0 0 20px 0;
                                    background-color: $White-Bg-1;
                                    .icon{
                                        width: 70px;
                                    }
                                }
                                .heading{
                                    color: $White-color;
                                }
                            }
                            .box-1,.box-2{
                                margin: 0 0 24px 0;
                            }
                            .box-1,.box-2,.box-3{
                                @include breakpoint($secreen-lg){
                                    margin: 0 0 24px 0;
                                }
                                @include breakpoint($secreen-max-md){
                                    margin: 0 0 24px 0;
                                }
                            }
                        }
                    }
                }
            }
            .col-art-otr{
                .col-art-inr{
                    padding: 0 0 0 110px;
                    @include breakpoint($secreen-max-xxlg){
                        padding: 0;
                    }
                    .art-img{
                        width: 100%;
                        height: 100%;
                        border-radius: 24px;
                    }
                }
            }
        }
    }
}

/*------------------------------------------
          Connect Wallet End Here
-------------------------------------------*/