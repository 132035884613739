

/*------------------------------------------
            FAQs Start Here
-------------------------------------------*/

.faq-main{
    margin: 128px 0;
    .container-fluid{
        .wrapper{
            margin: 0 0 72px 0;
            .tab-otr{
                display: flex;
                align-items: center;
                @include breakpoint($secreen-max-sm){
                    flex-direction: column;
                    align-items: flex-start;
                }
                .heading{
                    color: $White-color;
                    margin: 0 24px 0 0;
                    @include breakpoint($secreen-max-sm){
                        margin: 0 0 24px 0;
                    }
                }
                .tab-inr{
                    position: relative;
                    .btn-drop{
                        display: flex;
                        align-items: center;
                        background-color: $White-Bg-1;
                        border: 2px solid $Primary-color;
                        border-radius: 12px;
                        padding: 6px 16px;
                        cursor: pointer;
                        .btn-drop-inr{
                            color: $White-color;
                        }
                        .arrow-down{
                            font-size: 18px;
                            color: $White-color;
                            margin: 0 0 0 8px;
                        }
                        .change-text{
                            color: $White-color;
                        }
                    }
                    .tabs-ul{
                        position: absolute;
                        left: 0;
                        top: 80px;
                        width: 250px;
                        border-radius: 24px;
                        background-color: $Primary-shade;
                        padding: 16px 24px 20px 24px;
                        opacity: 0;
                        visibility: hidden;
                        transition: .3s;
                        z-index: 10;
                        .tab-link{
                            cursor: pointer;
                            &:not(:last-child){
                                margin-bottom: 12px;
                            }
                            .tab-p{
                                color: $White-color;
                            }
                        }
                        .active{
                            .tab-p{
                                color: $Primary-color;
                            }
                        }
                    }
                    .tab-drop-open{
                        opacity: 1;
                        visibility: visible;
                        top: 50px;
                    }
                }
            }
        }
        .row-custom-main{
            .tab-content{
                display: none;
                opacity: 0;
                transform: translateY(0);
                animation: fadeIn 0.3s ease 1 forwards;
                .row-custom-inr{
                    .col-otr{
                        .col-inr{
                            .accordion{
                                .accordion-item{
                                    border-radius: 16px;
                                    border: 2px solid $Primary-color;
                                    background-color: $White-Bg-1;
                                    overflow: hidden;
                                    @include breakpoint($secreen-max-md){
                                        margin-bottom: 24px;
                                    }
                                    &:not(:last-child){
                                        margin: 0 0 24px 0;
                                    }
                                    .accordion-header{
                                        .accordion-button{
                                            background-color: $White-Bg-1;
                                            border-radius: 16px;
                                            color: $White-color;
                                            padding: 12px 24px;
                                            border: none;
                                            box-shadow: none;
                                            &:focus{
                                                border-radius: 16px 16px 0 0;
                                                border: none;
                                                box-shadow: none;
                                            }
                                            &::after{
                                                flex-shrink: 0;
                                                width: 1.25rem;
                                                height: 1.25rem;
                                                margin-left: auto;
                                                content: "";
                                                background-image: url(/assets/img/arrow-drop-down.svg);
                                                background-repeat: no-repeat;
                                                background-size: 1.25rem;
                                                transition: transform .2s ease-in-out;
                                            }
                                        }
                                    }
                                    .accordion-collapse{
                                        transition: .5s;
                                        .accordion-body{
                                            padding: 16px 24px 20px 24px;
                                            color: $Shade-1;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .active{
                display: block;
                opacity: 1;
            }
            .col-btn-otr{
                .col-btn-inr{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 48px 0 0 0;
                    @include breakpoint($secreen-xs){
                        flex-direction: column;
                        align-items: flex-start;
                    }
                    .desc{
                        color: $White-color;
                        margin: 0 18px 0 0;
                        @include breakpoint($secreen-xs){
                            margin: 0 0 24px 0;
                        }
                    }
                }
            }
        }
    }
}

/*------------------------------------------
            FAQs End Here
-------------------------------------------*/