
/*------------------------------------------
            Hero Start Here
-------------------------------------------*/

.hero-main{
    padding: 128px 0;
    background-image: url('/assets/img/hero-bg-img.png');
    background-size: cover;
    overflow: hidden;
    .container-fluid{
        padding: 0 100px;
        @include breakpoint($secreen-max-xlg){
            padding: 0 50px;
        }
        @include breakpoint($secreen-max-md){
            padding: 0 15px;
        }
        .row-custom{
            align-items: center;
            @include breakpoint($secreen-max-xl){
                flex-direction: column-reverse;
            }
            .col-content-otr{
                @include breakpoint($secreen-max-xl){
                    width: 100%;
                }
                .col-content-inr{
                    padding-right: 50px;
                    @include breakpoint($secreen-max-xlg){
                        padding: 0;
                    }
                    @include breakpoint($secreen-max-xl){
                        padding: 72px 0 0 0;
                        text-align: center;
                    }
                    @include breakpoint($secreen-max-sm){
                        text-align: left;
                    }
                    .heading{
                        color: $White-color;
                        .text-color{
                            color: $Primary-color;
                        }
                    }
                    .desc{
                        color: $Shade-1;
                        padding: 20px 0 40px 0;
                    }
                    .action{
                        display: flex;
                        @include breakpoint($secreen-max-xl){
                            text-align: center;
                            justify-content: center;
                        }
                        @include breakpoint($secreen-max-sm){
                            justify-content: flex-start;
                        }
                        @include breakpoint($secreen-xxs){
                            flex-direction: column;
                        }
                        .left-btn{
                            margin-right: 24px;
                            @include breakpoint($secreen-xxs){
                                margin: 0 0 24px 0;
                            }
                        }
                    }
                    .staticstics{
                        display: flex;
                        margin: 40px 0 0 0;
                        @include breakpoint($secreen-max-xl){
                            text-align: center;
                            justify-content: center;
                        }
                        @include breakpoint($secreen-max-sm){
                            justify-content: flex-start;
                            text-align: left;
                        }
                        @include breakpoint($secreen-xxs){
                            flex-direction: column;
                        }
                        .staticstics-box{
                            padding: 24px 28px 30px 28px;
                            border-radius: 16px;
                            background-color: $White-Bg-1;
                            &:not(:last-child){
                                margin-right: 24px;
                                @include breakpoint($secreen-xxs){
                                    margin: 0 0 24px 0;
                                }
                            }
                            .static-head{
                                color: $White-color;
                            }
                            .static-desc{
                                color: $White-color;
                            }
                        }
                    }
                }
            }
            .col-img-otr{
                @include breakpoint($secreen-max-xl){
                    width: 60%;
                }
                @include breakpoint($secreen-max-md){
                    width: 80%;
                }
                @include breakpoint($secreen-max-sm){
                    width: 100%;
                }
                .col-img-inr{
                    border-radius: 24px;
                    background-color: $White-Bg-1;
                    padding: 36px 36px 34px 36px;
                    margin-left: 50px;
                    @include breakpoint($secreen-max-lg){
                        margin-left: 0;
                    }
                    @include breakpoint($secreen-xs){
                        padding: 28px 28px 24px 28px;
                    }
                    .img-otr{
                        position: relative;
                        .hero-img{
                            border-radius: 16px;
                        }
                        .bid-main{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 16px 0px 16px 20px;
                            border-radius: 8px;
                            background-color: $White-bg-2;
                            position: absolute;
                            left: 50%;
                            transform: translateX(-50%);
                            bottom: 36px;
                            width: calc(100% - 72px);
                            margin: 0 auto;
                            @include breakpoint($secreen-xs){
                                display: none;
                            }
                            .left-bid{
                                position: relative;
                                z-index: 1;
                                .bid-desc{
                                    color: $White-color;
                                }
                                .ETH{
                                    color: $Primary-color;
                                }
                            }
                            .right-bid{
                                position: relative;
                                z-index: 1;
                                width: 125px;
                                .end-desc{
                                    color: $White-color;
                                }
                                .timer{
                                    color: $Primary-color;
                                }
                            }
                            .blur-effect{
                                backdrop-filter: blur(25px);
                            }
                        }
                        .time-otr{
                            display: none;
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            background-color: $White-bg-2;
                            z-index: 10;
                            border-radius: 14px;
                            padding: 6px 16px;
                            backdrop-filter: blur(25px);
                            @include breakpoint($secreen-xs){
                                display: block;
                            }
                            .timer{
                                color: $White-color;
                            }
                        }
                        .heart-main{
                            display: none;
                            position: absolute;
                            top: 24px;
                            right: 24px;
                            @include breakpoint($secreen-xs){
                                display: block;
                            }
                            .heart-icon-otr{
                                width: 52px;
                                height: 52px;
                                border-radius: 100%;
                                display: flex;
                                background-color: $White-Bg-1;
                                backdrop-filter: blur(25px);
                                position: relative;
                                cursor: pointer;
                                .heart-icon{
                                    position: absolute;
                                    left: 50%;
                                    top: 50%;
                                    transform: translate(-50%, -50%);
                                    font-size: 24px;
                                    color: $Primary-color;
                                }
                                .ri-heart-fill{
                                    color: red;
                                }
                            }
                        }
                    }
                    .profile-main{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin: 24px 0 0 0;
                        .profile-inr{
                            display: flex;
                            .img-otr{
                                position: relative;
                                transition: .2s;
                                .img-inr{
                                    .avatar-img{
                                        height: 56px;
                                        width: 56px;
                                        border-radius: 100%;
                                        border: 2px solid $White-color;
                                        @include breakpoint($secreen-xs){
                                            width: 40px;
                                            height: 40px;
                                        }
                                    }
                                }
                                .check-icon-otr{
                                    width: 20px;
                                    height: 20px;
                                    border-radius: 100px;
                                    background-color: $Primary-color;
                                    border: 2px solid $White-color;
                                    position: absolute;
                                    display: flex;
                                    right: 0;
                                    bottom: 0;
                                    @include breakpoint($secreen-xs){
                                        width: 16px;
                                        height: 16px;
                                    }
                                    .check-icon{
                                        position: relative;
                                        top: 40%;
                                        left: 50%;
                                        transform: translate(-50%, -50%);
                                        color: $White-color;
                                        font-size: 14px;
                                        @include breakpoint($secreen-xs){
                                            top: 30%;
                                            font-size: 12px;
                                        }
                                    }
                                }
                            }
                            .img-1,.img-2,.img-3{
                                position: relative;
                                &:hover{
                                    z-index: 1;
                                    transform: translateY(-3px);
                                    .hover{
                                        opacity: 1;
                                        visibility: visible;
                                    }
                                }
                                .hover{
                                    position: absolute;
                                    text-align: center;
                                    width: 260px;
                                    background-color: $Primary-shade;
                                    border: 1px solid $Primary-color;
                                    border-radius: 8px;
                                    left: 0;
                                    top: -60px;
                                    transition: .3s;
                                    opacity: 0;
                                    visibility: hidden;
                                    @include breakpoint($secreen-max-md){
                                        display: none;
                                    }
                                    .text{
                                        color: $White-color;
                                        padding: 6px 16px;
                                    }
                                }
                            }
                            .img-2{
                                margin-left: -25px;
                                @include breakpoint($secreen-xs){
                                    margin-left: -15px;
                                }
                            }
                            .img-3{
                                margin-left: -25px;
                                @include breakpoint($secreen-xs){
                                    margin-left: -15px;
                                }
                            }
                        }
                        .heart-main{
                            display: flex;
                            align-items: center;
                            @include breakpoint($secreen-xs){
                                display: none;
                            }
                            .heart-icon-otr{
                                width: 52px;
                                height: 52px;
                                border-radius: 100%;
                                display: flex;
                                background-color: $White-Bg-1;
                                position: relative;
                                cursor: pointer;
                                .heart-icon{
                                    position: absolute;
                                    left: 50%;
                                    top: 50%;
                                    transform: translate(-50%, -50%);
                                    font-size: 24px;
                                    color: $Primary-color;
                                }
                                .ri-heart-fill{
                                    color: red;
                                }
                            }
                            .likes{
                                color: $Shade-1;
                                margin-left: 16px;
                            }
                        }
                        .left-bid{
                            display: none;
                            @include breakpoint($secreen-xs){
                                display: block;
                            }
                            .bid-desc{
                                color: $White-color;
                            }
                            .ETH{
                                color: $Primary-color;
                            }
                        }
                    }
                }
            }
        }
    }
}

/*------------------------------------------
            Hero End Here
-------------------------------------------*/

/*------------------------------------------
            Auction Start Here
-------------------------------------------*/

.auction-main{
    margin: 128px 0;
    overflow: hidden;
    .container-fluid{
        .wrapper{
            margin: 0 0 72px 0;
            .heading{
                color: $White-color;
            }
        }
        .row-auction{
            .col-otr{
                .col-inr{
                    background-color: $White-Bg-1;
                    border-radius: 24px;
                    padding: 28px 24px 32px 24px;
                    .avatar-main{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin: 0 0 24px 0;
                        .profile-inr{
                            display: flex;
                            .img-otr{
                                position: relative;
                                transition: .2s;
                                .img-inr{
                                    .avatar-img{
                                        height: 40px;
                                        width: 40px;
                                        border-radius: 100%;
                                        border: 2px solid $White-color;
                                    }
                                }
                                .check-icon-otr{
                                    width: 16px;
                                    height: 16px;
                                    border-radius: 100px;
                                    background-color: $Primary-color;
                                    border: 1px solid $White-color;
                                    position: absolute;
                                    display: flex;
                                    right: 0;
                                    bottom: 0;
                                    .check-icon{
                                        position: relative;
                                        top: 43%;
                                        left: 50%;
                                        transform: translate(-50%, -50%);
                                        color: $White-color;
                                        font-size: 12px;
                                    }
                                }
                            }
                            .img-1,.img-2,.img-3{
                                &:hover{
                                    z-index: 1;
                                    transform: translateY(-3px);
                                    .hover{
                                        opacity: 1;
                                        visibility: visible;
                                    }
                                }
                                .hover{
                                    position: absolute;
                                    text-align: center;
                                    width: 260px;
                                    background-color: $Primary-shade;
                                    border: 1px solid $Primary-color;
                                    border-radius: 8px;
                                    left: 0;
                                    top: -60px;
                                    transition: .3s;
                                    opacity: 0;
                                    visibility: hidden;
                                    @include breakpoint($secreen-max-md){
                                        display: none;
                                    }
                                    .text{
                                        color: $White-color;
                                        padding: 6px 16px;
                                    }
                                }
                            }
                            .img-2{
                                margin-left: -20px;
                            }
                            .img-3{
                                margin-left: -20px;
                            }
                        }
                        .list-icon-otr{
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            width: 20px;
                            cursor: pointer;
                            .icon{
                                width: auto;
                            }
                        }
                    }
                    .cover-img-otr{
                        position: relative;
                        .cover-img{
                            border-radius: 16px;
                        }
                        .time-otr{
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            background-color: $White-bg-2;
                            z-index: 10;
                            border-radius: 14px;
                            width: 124px;
                            height: 40px;
                            backdrop-filter: blur(25px);
                            .timer{
                                position: absolute;
                                left: 65%;
                                top: 50%;
                                transform: translate(-50%, -50%);
                                width: 100%;
                                color: $White-color;
                            }
                        }
                        .heart-icon-otr2{
                            position: absolute;
                            width: 52px;
                            height: 52px;
                            border-radius: 100%;
                            display: flex;
                            background-color: $White-bg-2;
                            cursor: pointer;
                            top: 24px;
                            right: 24px;
                            backdrop-filter: blur(25px);
                            .heart-icon2{
                                position: absolute;
                                left: 50%;
                                top: 50%;
                                transform: translate(-50%, -50%);
                                font-size: 24px;
                                color: $White-color;
                            }
                            .ri-heart-fill{
                                color: red;
                            }
                        }
                    }
                    .art-name{
                        display: inline-flex;
                        color: $White-color;
                        margin: 18px 0;
                        transition: .3s;
                        &:hover{
                            color: $Primary-color;
                        }
                    }
                    .bid-main{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        background-color: $White-Bg-1;
                        padding: 16px 20px;
                        border-radius: 8px;
                        .bid{
                            color: $Shade-1;
                        }
                        .Price{
                            color: $White-color;
                        }
                    }
                }
            }
        }
    }
}
.owl-stage-outer{
    overflow: visible !important;
}
.owl-theme .owl-nav [class*=owl-]:hover{
    
}
.owl-theme .owl-nav{
    margin: 0 !important;
    text-align: right !important;
    position: absolute !important;
    top: -122px !important;
    width: 100% !important;
    .owl-prev{
        width: 52px;
        height: 52px;
        border-radius: 100%;
        background-color: $White-Bg-1 !important;
    }
    .owl-next{
        width: 52px;
        height: 52px;
        border-radius: 100%;
        background-color: $White-Bg-1 !important;
    }
}
.owl-carousel .owl-stage{
    transition: 1s ease-in-out !important;
}
.owl-theme .owl-nav [class*=owl-]{
    margin: 0 !important;
}
.owl-carousel .owl-nav .owl-prev{
    margin-right: 24px !important;
}
.selected{
    path{
        fill: #a83f39;
        stroke: #a83f39;
        transition: .5s;
    }
}
.owl-theme .owl-nav.disabled + .owl-dots{
    margin: 48px 0 0 0;
}
.owl-theme .owl-dots .owl-dot span{
    width: 8px;
    height: 8px;
    background-color: $White-color;
    transition: .3s;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
    width: 24px;
    background-color: $Primary-color;
}

/*------------------------------------------
            Auction End Here
-------------------------------------------*/

/*------------------------------------------
            Creator Start Here
-------------------------------------------*/

.creator-main{
    margin: 128px 0;
    overflow: hidden;
    .container-fluid{
        .wrapper{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0 0 72px 0;
            .heading{
                color: $White-color;
            }
            .view-all{
                display: flex;
                align-items: center;
                @include breakpoint($secreen-xs){
                    display: none;
                }
                .view{
                    color: $Primary-color;
                    margin: 0 12px 0 0;
                }
                .arrow-right{
                    color: $Primary-color;
                    font-size: 24px;
                }
            }
        }
        .row-creator{
            .col-otr{
                @include breakpoint($secreen-lg){
                    width: 33.333%;
                }
                .col-inr{
                    .col-box{
                        display: flex;
                        align-items: center;
                        background-color: $White-Bg-1;
                        border-radius: 16px;
                        padding: 24px 28px;
                        .avtar-otr{
                            width: 62px;
                            height: 62px;
                            border-radius: 100px;
                            position: relative;
                            .avatar{
                                height: 100%;
                                border-radius: 100%;
                                border: 2px solid $White-color;
                            }
                            .check-icon-otr{
                                width: 20px;
                                height: 20px;
                                border-radius: 100px;
                                background-color: $Primary-color;
                                border: 2px solid $White-color;
                                position: absolute;
                                display: flex;
                                right: 2px;
                                bottom: 0;
                                @include breakpoint($secreen-xs){
                                    width: 16px;
                                    height: 16px;
                                }
                                .check-icon{
                                    position: relative;
                                    top: 40%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                    color: $White-color;
                                    font-size: 14px;
                                    @include breakpoint($secreen-xs){
                                        top: 30%;
                                        font-size: 12px;
                                    }
                                }
                            }
                        }
                        .content-otr{
                            margin: 0 0 0 16px;
                            .creator-name{
                                color: $White-color;
                            }
                            .price{
                                color: $Primary-color;
                            }
                        }
                    }
                }
                .box-1,.box-2,.box-3,.box-4{
                    margin: 0 0 24px 0;
                }
                .box-1,.box-2,.box-3,.box-4,.box-5,.box-6{
                    @include breakpoint($secreen-max-md){
                        margin: 0 0 24px 0;
                    }
                }
                .box-1,.box-2,.box-3,.box-4,.box-5,.box-6,.box-7{
                    @include breakpoint($secreen-xs){
                        margin: 0 0 24px 0;
                    }
                }
            }
        }
        .responsive{
            display: none;
            align-items: center;
            justify-content: center;
            margin: 48px 0 0 0;
            @include breakpoint($secreen-xs){
                display: flex;
            }
            .view-all{
                display: flex;
                align-items: center;
                .view{
                    color: $Primary-color;
                    margin: 0 12px 0 0;
                }
                .arrow-right{
                    color: $Primary-color;
                    font-size: 24px;
                }
            }
        }
    }
}

/*------------------------------------------
            Creator End Here
-------------------------------------------*/

/*------------------------------------------
        Feature Artwork Start Here
-------------------------------------------*/

.feature-main{
    margin: 128px 0;
    overflow: hidden;
    .container-fluid{
        .wrapper{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0 0 72px 0;
            .heading{
                color: $White-color;
            }
            .view-all{
                display: flex;
                align-items: center;
                @include breakpoint($secreen-xs){
                    display: none;
                }
                .view{
                    color: $Primary-color;
                    margin: 0 12px 0 0;
                }
                .arrow-right{
                    color: $Primary-color;
                    font-size: 24px;
                }
            }
        }
        .row-custom{
            .col-otr{
                @include breakpoint($secreen-max-xlg){
                    width: 33.333%;
                }
                @include breakpoint($secreen-max-md){
                    width: 50%;
                }
                @include breakpoint($secreen-custom){
                    width: 100%;
                }
                .col-inr{
                    background-color: $White-Bg-1;
                    border-radius: 24px;
                    padding: 28px 24px 32px 24px;
                    .avatar-main{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin: 0 0 24px 0;
                        .profile-inr{
                            display: flex;
                            .img-otr{
                                position: relative;
                                transition: .2s;
                                .img-inr{
                                    .avatar-img{
                                        height: 40px;
                                        width: 40px;
                                        border-radius: 100%;
                                        border: 2px solid $White-color;
                                    }
                                }
                                .check-icon-otr{
                                    width: 16px;
                                    height: 16px;
                                    border-radius: 100px;
                                    background-color: $Primary-color;
                                    border: 1px solid $White-color;
                                    position: absolute;
                                    display: flex;
                                    right: 0;
                                    bottom: 0;
                                    .check-icon{
                                        position: relative;
                                        top: 43%;
                                        left: 50%;
                                        transform: translate(-50%, -50%);
                                        color: $White-color;
                                        font-size: 12px;
                                    }
                                }
                            }
                            .img-1,.img-2,.img-3{
                                &:hover{
                                    z-index: 1;
                                    transform: translateY(-3px);
                                    .hover{
                                        opacity: 1;
                                        visibility: visible;
                                    }
                                }
                                .hover{
                                    position: absolute;
                                    text-align: center;
                                    width: 260px;
                                    background-color: $Primary-shade;
                                    border: 1px solid $Primary-color;
                                    border-radius: 8px;
                                    left: 0;
                                    top: -60px;
                                    transition: .3s;
                                    opacity: 0;
                                    visibility: hidden;
                                    @include breakpoint($secreen-max-md){
                                        display: none;
                                    }
                                    .text{
                                        color: $White-color;
                                        padding: 6px 16px;
                                    }
                                }
                            }
                            .img-2{
                                margin-left: -20px;
                            }
                            .img-3{
                                margin-left: -20px;
                            }
                        }
                        .list-icon-otr{
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            cursor: pointer;
                            width: 20px;
                            .icon{
                                width: auto;
                            }
                        }
                    }
                    .cover-img-otr{
                        position: relative;
                        .cover-img{
                            width: 100%;
                            border-radius: 16px;
                        }
                        .time-otr{
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            background-color: $White-bg-2;
                            z-index: 10;
                            border-radius: 14px;
                            width: 124px;
                            height: 40px;
                            backdrop-filter: blur(25px);
                            .timer{
                                position: absolute;
                                left: 65%;
                                top: 50%;
                                transform: translate(-50%, -50%);
                                width: 100%;
                                color: $White-color;
                            }
                        }
                        .heart-icon-otr2{
                            position: absolute;
                            width: 52px;
                            height: 52px;
                            border-radius: 100%;
                            display: flex;
                            background-color: $White-bg-2;
                            cursor: pointer;
                            top: 24px;
                            right: 24px;
                            backdrop-filter: blur(25px);
                            .heart-icon2{
                                position: absolute;
                                left: 50%;
                                top: 50%;
                                transform: translate(-50%, -50%);
                                font-size: 24px;
                                color: $White-color;
                            }
                            .ri-heart-fill{
                                color: red;
                            }
                        }
                    }
                    .art-name{
                        display: inline-flex;
                        color: $White-color;
                        margin: 18px 0;
                        transition: .3s;
                        &:hover{
                            color: $Primary-color;
                        }
                    }
                    .bid-main{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        background-color: $White-Bg-1;
                        padding: 16px 20px;
                        border-radius: 8px;
                        .bid{
                            color: $Shade-1;
                        }
                        .Price{
                            color: $White-color;
                        }
                    }
                }
                .box-1,.box-2,.box-3,.box-4{
                    margin: 0 0 24px 0;
                }
                .box-1,.box-2,.box-3,.box-4,.box-5,.box-6{
                    @include breakpoint($secreen-max-md){
                        margin: 0 0 24px 0;
                    }
                }
                .box-1,.box-2,.box-3,.box-4,.box-5,.box-6,.box-7{
                    @include breakpoint($secreen-custom){
                        margin: 0 0 24px 0;
                    }
                }
            }
        }
        .responsive{
            display: none;
            align-items: center;
            justify-content: center;
            margin: 48px 0 0 0;
            @include breakpoint($secreen-xs){
                display: flex;
            }
            .view-all{
                display: flex;
                align-items: center;
                .view{
                    color: $Primary-color;
                    margin: 0 12px 0 0;
                }
                .arrow-right{
                    color: $Primary-color;
                    font-size: 24px;
                }
            }
        }
    }
}

/*------------------------------------------
        Feature Artwork End Here
-------------------------------------------*/

/*------------------------------------------
        Popular Collection Start Here
-------------------------------------------*/

.collection-main{
    margin: 128px 0;
    overflow: hidden;
    .container-fluid{
        .wrapper{
            margin: 0 0 72px 0;
            .heading{
                color: $White-color;
            }
        }
        .row-collection{
            .col-otr{
                .col-inr{
                    position: relative;
                    overflow: hidden;
                    border-radius: 24px;
                    .img-otr{
                        .cover-img{
                            width: 100%;
                        }
                    }
                    .creator-otr{
                        width: 100%;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        background-color: $White-Bg-1;
                        backdrop-filter: blur(25px);
                        .col-box{
                            display: flex;
                            align-items: center;
                            padding: 28px 24px;
                            .avtar-otr{
                                width: 72px;
                                height: 72px;
                                margin: 0 16px 0 0;
                                position: relative;
                                .avatar{
                                    border-radius: 100%;
                                    border: 2px solid $White-color;
                                }
                                .check-icon-otr{
                                    width: 20px;
                                    height: 20px;
                                    border-radius: 100px;
                                    background-color: $Primary-color;
                                    border: 2px solid $White-color;
                                    position: absolute;
                                    display: flex;
                                    right: 2px;
                                    bottom: 0;
                                    @include breakpoint($secreen-xs){
                                        width: 16px;
                                        height: 16px;
                                    }
                                    .check-icon{
                                        position: relative;
                                        top: 40%;
                                        left: 50%;
                                        transform: translate(-50%, -50%);
                                        color: $White-color;
                                        font-size: 14px;
                                        @include breakpoint($secreen-xs){
                                            top: 30%;
                                            font-size: 12px;
                                        }
                                    }
                                }
                            }
                            .content-otr{
                                .product{
                                    color: $White-color;
                                    @include breakpoint($secreen-xs){
                                        font-size: $font-size-S;
                                        line-height: $line-height-S;
                                        font-family: $font-family-Montserrat-Bold;
                                    }
                                }
                                .creator-name{
                                    color: $Shade-1;
                                    .name-inr{
                                        color: $Primary-color;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.owl-stage-outer{
    overflow: visible !important;
}
.owl-theme .owl-nav [class*=owl-]:hover{
    
}
.owl-theme .owl-nav{
    margin: 0 !important;
    text-align: right !important;
    position: absolute !important;
    top: -122px !important;
    width: 100% !important;
    .owl-prev{
        width: 52px;
        height: 52px;
        border-radius: 100%;
        background-color: $White-Bg-1 !important;
    }
    .owl-next{
        width: 52px;
        height: 52px;
        border-radius: 100%;
        background-color: $White-Bg-1 !important;
    }
}
.owl-carousel .owl-stage{
    transition: 1s ease-in-out !important;
}
.owl-theme .owl-nav [class*=owl-]{
    margin: 0 !important;
}
.owl-carousel .owl-nav .owl-prev{
    margin-right: 24px !important;
}
.selected{
    path{
        fill: #a83f39;
        stroke: #a83f39;
        transition: .5s;
    }
}

/*------------------------------------------
        Popular Collection End Here
-------------------------------------------*/

/*------------------------------------------
        Call to action Start Here
-------------------------------------------*/

.cal-to-action-main{
    margin: 128px 0;
    overflow: hidden;
    .container-fluid{
        position: relative;
        .row-custom{
            width: 100%;
            margin: 0;
            display: flex;
            justify-content: space-between;
            overflow: hidden;
            background-image: url(/assets/img/call-to-action-bg.png);
            border-radius: 24px;
            .col-content-otr{
                .col-content-inr{
                    padding: 72px 0 96px 72px;
                    @include breakpoint($secreen-lg){
                        padding: 72px 0 96px 32px;
                    }
                    @include breakpoint($secreen-xs){
                        padding: 48px 15px 56px 15px;
                    }
                    .heading{
                        color: $White-color;
                        @include breakpoint($secreen-max-xl){
                            br{
                                display: none;
                                @include breakpoint($secreen-max-md){
                                    display: block;
                                }
                                @include breakpoint($secreen-xs){
                                    display: none;
                                }
                            }
                        }
                        .heading-inr{
                            color: $Primary-color;
                        }
                    }
                    .desc{
                        color: $Shade-1;
                        margin: 8px 0 32px 0;
                        @include breakpoint($secreen-max-xl){
                            br{
                                display: none;
                                @include breakpoint($secreen-max-md){
                                    display: block;
                                }
                                @include breakpoint($secreen-xs){
                                    display: none;
                                }
                            }
                        }
                    }
                    .btn-otr{
                        display: flex;
                        align-items: center;
                        @include breakpoint($secreen-xxs){
                            flex-direction: column;
                        }
                        .action{
                            @include breakpoint($secreen-xxs){
                                width: 100%;
                                text-align: center;
                            }
                            &:not(:last-child){
                                margin: 0 24px 0 0;
                                @include breakpoint($secreen-xxs){
                                    margin: 0 0 24px 0;
                                }
                            }
                            .btn-action{
                            }
                        }
                    }
                }
            }
        }
        .art-img{
            position: absolute;
            right: 130px;
            bottom: 0;
            height: 95%;
            @include breakpoint($secreen-max-md){
                display: none;
            }
        }
    }
}

/*------------------------------------------
        Call to action End Here
-------------------------------------------*/

/*------------------------------------------
            Blog Start Here
-------------------------------------------*/

.blog-main{
    margin: 128px 0;
    overflow: hidden;
    .container-fluid{
        .wrapper{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0 0 72px 0;
            .heading{
                color: $White-color;
            }
            .view-all{
                display: flex;
                align-items: center;
                @include breakpoint($secreen-xs){
                    display: none;
                }
                .view{
                    color: $Primary-color;
                    margin: 0 12px 0 0;
                }
                .arrow-right{
                    color: $Primary-color;
                    font-size: 24px;
                }
            }
        }
        .row-custom{
            .col-otr{
                @include breakpoint($secreen-lg){
                    width: 50%;
                }
                .col-inr{
                    background-color: $White-Bg-1;
                    backdrop-filter: blur(25px);
                    border-radius: 24px;
                    padding: 36px 36px 32px 36px;
                    @include breakpoint($secreen-xs){
                        padding: 28px 28px 24px 28px;
                    }
                    .img-otr{
                        .blog-img{
                            width: 100%;
                            border-radius: 16px;
                        }
                    }
                    .content-otr{
                        .date-otr{
                            color: $Primary-color;
                            margin: 16px 0 8px 0;
                            .date-inr{
                                color: $Shade-2;
                            }
                        }
                        .heading{
                            display: inline;
                            color: $White-color;
                            transition: .3s;
                            &:hover{
                                color: $Primary-color;
                            }
                        }
                        .desc{
                            color: $Shade-1;
                            margin: 8px 0 0 0;
                        }
                    }
                }
                .box-1,.box-2{
                    @include breakpoint($secreen-max-lg){
                        margin: 0 0 24px 0;
                    }
                }
            }
        }
        .responsive{
            display: none;
            align-items: center;
            justify-content: center;
            margin: 48px 0 0 0;
            @include breakpoint($secreen-xs){
                display: flex;
            }
            .view-all{
                display: flex;
                align-items: center;
                .view{
                    color: $Primary-color;
                    margin: 0 12px 0 0;
                }
                .arrow-right{
                    color: $Primary-color;
                    font-size: 24px;
                }
            }
        }
    }
}

/*------------------------------------------
            Blog End Here
-------------------------------------------*/
