

/*------------------------------------------
          Upload Start Here
-------------------------------------------*/

.upload-main{
    margin: 128px 0;
    .container-fluid{
        .row-custom{
            display: flex;
            align-items: center;
            justify-content: center;
            .col-otr{
                .col-inr{
                    .wrapper{
                        text-align: center;
                        .heading{
                            color: $White-color;
                            margin: 0 0 16px 0;
                        }
                        .desc{
                            color: $Shade-1;
                            @include breakpoint($secreen-max-sm){
                                br{
                                    display: none;
                                }
                            }
                            .linkk{
                                display: inline;
                                color: $Primary-color;
                            }
                        }
                    }
                    .create-main{
                        display: flex;
                        align-items: center;
                        margin: 72px 0;
                        @include breakpoint($secreen-max-sm){
                            flex-direction: column;
                        }
                        .create{
                            width: 50%;
                            background-color: $White-Bg-1;
                            border-radius: 24px;
                            padding: 40px 36px;
                            @include breakpoint($secreen-max-sm){
                                width: 70%;
                                padding: 24px;
                            }
                            @include breakpoint($secreen-xxs){
                                width: 100%;
                            }
                            &:not(:last-child){
                                margin-right: 24px;
                                @include breakpoint($secreen-max-sm){
                                    margin: 0 0 24px 0;
                                }
                            }
                            .img-otr{
                                .img{
                                    width: 100%;
                                    border-radius: 16px;
                                    margin: 0 0 24px 0;
                                }
                            }
                            .action{
                                display: flex;
                                text-align: center;
                                .btn-single{
                                    width: 100%;
                                }
                            }
                        }
                    }
                    .text{
                        color: $Shade-1;
                        text-align: center;
                    }
                }
            }
        }
    }
}

/*------------------------------------------
          Upload End Here
-------------------------------------------*/